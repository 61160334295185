import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";

export const pageQuery = graphql`
  query PrivacyQuery {
    prismicSeo(data: { name: { eq: "Mosano | Privacy Policy" } }) {
      data {
        name
        title
        description
        keywords
        canonical_url {
          url
        }
        image {
          url
        }
      }
    }
    prismicTerms(uid: { eq: "terms" }) {
      data {
        terms_text {
          html
        }
      }
      uid
    }
    prismicCookieConsent(uid: { eq: "cookie_consent" }) {
      data {
        setting_button_label
        accept_button_label
        decline_button_label
        close_button_label
        close_icon_alt
        title {
          text_1
          text_2
          text_3
        }
        cookies_categories {
          name
          details {
            text
          }
          policy_link {
            url
          }
          policy_link_text
          expand_button_label
          status
        }
      }
    }
  }
`;

const Privacypolicy = (props: any) => {
  const privacy = props.data.prismicTerms || [];
  return (
    <>
      <SEO {...props.data.prismicSeo.data} />
      <div className="gradient" style={{ padding: "150px 0 40px" }}>
        <div className="container-hero-home">
          <h1 className="heading large">Data Policy</h1>
          <div
            className="p1 cookies"
            dangerouslySetInnerHTML={{ __html: privacy.data.terms_text.html }}
          />
        </div>
      </div>
    </>
  );
};

export default Privacypolicy;
